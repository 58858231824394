<template>
    <component :is="layout" />
</template>

<script>
import MainLayout from '@/shared/main-layout';
import EmptyLayout from '@/shared/empty-layout';

export default {
    name: 'App',
    components: { MainLayout, EmptyLayout },
    data() {
        return {
            layout: null
        };
    },
    watch: {
        $route(to) {
            // set layout by route meta
            var body = document.body;
            if (to.meta.isClientPage) {
                body.classList.add('clientPage-header');
            } else {
                body.classList.remove('clientPage-header');
            }
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
                body.classList.remove('whitesmoke');
            } else {
                this.layout = 'MainLayout'; // this is default layout if route meta is not set
                if (
                    to.name != 'ra001500v' &&
                    to.name != 'ra002500v' &&
                    to.name != 'ra002600v' &&
                    to.name != 'ra002700v' &&
                    to.name != 'ra300600v' &&
                    to.name != 'ra003600s' &&
                    to.name != 'ra400500v' &&
                    (!to.meta.isClientPage ||
                        to.name == 'ra200900i' ||
                        to.name == 'ra400400i') &&
                    !to.meta.isIsMobile
                ) {
                    body.classList.add('whitesmoke');
                } else {
                    body.classList.remove('whitesmoke');
                }
            }
            if (to.meta.isIsMobile) {
                body.classList.add('mobilePage');
            } else {
                body.classList.remove('mobilePage');
            }
        }
    }
};
</script>

<style lang="scss">
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
@import '@/assets/scss/common/common.scss';
@import '@/assets/scss/common/basscss.min.scss';
</style>
