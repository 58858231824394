import login from '@/views/auth/login/label';
import loginMail from '@/views/auth/loginMail/label';
import passwordChange from '@/views/auth/passwordChange/label';
import ra000400m from '@/views/ra/ra000400m/label';
import ra000500m from '@/views/ra/ra000500m/label';
import ra000600m from '@/views/ra/ra000600m/label';
import ra000700m from '@/views/ra/ra000700m/label';
import ra000800m from '@/views/ra/ra000800m/label';
import ra000900m from '@/views/ra/ra000900m/label';
import ra001000m from '@/views/ra/ra001000m/label';
import ra001200m from '@/views/ra/ra001200m/label';
import ra001600i from '@/views/input/ra001600i/label';
import ra200900i from '@/views/input/ra200900i/label';
import ra300200i from '@/views/input/ra300200i/label';
import ra300400i from '@/views/input/ra300400i/label';

export default {
    login: login,
    loginMail: loginMail,
    passwordChange: passwordChange,
    ra000400m: ra000400m,
    ra000500m: ra000500m,
    ra000600m: ra000600m,
    ra000700m: ra000700m,
    ra000800m: ra000800m,
    ra000900m: ra000900m,
    ra001000m: ra001000m,
    ra001200m: ra001200m,
    ra001600i: ra001600i,
    ra200900i: ra200900i,
    ra300200i: ra300200i,
    ra300400i: ra300400i
};
