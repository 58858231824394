import repository from '@/utils/repository';

const resource = '/inquiry/ra201000v';

export default {
    getAll: (condition) => {
        return repository.get(`${resource}`, {
            params: condition
        });
    },
    post: (data) => {
        return repository.post(`${resource}`, data);
    },
    delete: (code) => {
        return repository.delete(`${resource}/${code}`);
    }
};
