export default {
    name: '取引先名',
    shortname: '取引先名略称',
    kana: '取引先名カナ',
    password: 'パスワード',
    reissue: '再発行',
    notice: '通知',
    limit: '有効期限',
    listofsubcontractors: '下請会社一覧',
    timeNotify: '最終送信日時'
};
