export default {
    code: '社員番号',
    name: '氏名',
    kana: 'カナ',
    eigyoshocode: '営業所',
    jigyobucode: '事業部・部',
    unitcode: 'ユニット・グループ',
    teamcode: 'チーム',
    email: 'メールアドレス',
    use: 'システム利用',
    limit: '有効期限',
    passwordnotification: 'パスワード通知',
    last_notification_date: '最終通知日時',
    senddatetime: '有効期限',
    kengen: '職務権限',
    kanri: 'システム管理者',
    qa: 'ＱＡ回答権限',
    shuning_kanrig: '技術者区分',
    sortno: '並び順',
    deleteauth: '物件削除'
};
