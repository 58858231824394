export default {
    // Confirm message
    C001: '入力データを新規登録しますか？',
    C002: '入力データで更新登録しますか？',
    C003: '参照データを削除しますか？',
    C004: '画面を初期化しますか？',
    C005: '従業員一覧をＣＳＶでエクスポートしますか？',
    C006: '会社マスタをＣＳＶでエクスポートしますか？',
    C007: '登録済みメールアドレスにパスワード通知を送信しますか？',
    C008: '営業所マスタをCSVでエクスポートしますか？',
    C009: '資格名マスタをCSVでエクスポートしますか？',
    C010: '<p>物件：{0}の全作業を完了しますか？</p><p>※完了すると物件情報の修正ができなくなります。</p>',
    C011: '<p>物件：{0}の全作業完了状態を解除しますか？</p><p>※解除をすると物件情報の修正ができるようになります。</p>',
    C012: 'KY項目・作業指示・基本遵守項目の一覧をCSVでエクスポートしますか？',
    C013: '職務権限マスタをCSVでエクスポートしますか？',
    C014: '<p>１次会社：{0}の完了報告を解除してもいいですか？</p><p>※解除すると１次会社で修正ができるようになります。</p>',
    C015: '<p>１次会社：{0}の全作業完了を承認しますか？</p><p>※承認されると１次会社作業の修正ができなくなります。</p>',
    C016: '<p>１次会社：{0}の全作業完了承認を解除しますか？</p><br><p>承認者　：{1}</p><p>承認日時：{2}</p><br><p>※承認解除しただけでは１次会社作業の修正はできません。</p><p>※承認解除後に、「完了報告の解除」すると　</p><p>１次会社作業の修正ができるようになります。  </p>',
    C017: '<p>入力データを一時保存しますか？</p><p>※一時保存されたデータは、呼び出す事で続きから入力できます。</p>',
    C018: '<p>入力データを新規物件として登録しますか？</p><p>※初期登録を行うとデータ登録後に担当者通知を行います。</p>',
    C019: '<p>入力データで更新登録しますか？</p>',
    C020: '<p>参照データを削除しますか？</p>',
    C021: '担当決定通知を送信しますか？',
    C022: '１次会社に作業依頼を送信しますか？',
    C023: '新規入場遵守事項一覧をＣＳＶでエクスポートしますか？',
    C024: '1次会社名マスタをCSVでエクスポートしますか？',
    C025: '作業依頼内容一覧をＣＳＶでエクスポートしますか？',
    C026: '<p>入力データで更新登録しますか？</p><br><p>変更メモの記載はしましたか？</p><p>記載していない場合には、戻って入力してください。</p>',
    C027: '<p>各作業状況については、以下の通りとなります。</p><br><ul><li>未受領：受領ボタンを押していない状態</li><li>着工済：作業が始まっている状態</li><li>未報告：物件全体の完了報告を行っていない状態</li><li>申請中：物件全体の完了報告をし、FCの承認待ちの状態</li><li>承認済：FCの承認が完了した状態　※承認後、15日間は物件内容の確認ができます</li></ul>',
    C028: '<p>{0}</p><p>{1}の作業報告を解除しますか？</p><p>※解除されると１次会社による作業報告ができるようになります。</p>',
    C029: '選択した作業日の安全書類を印刷しますか？',
    C030: '受領しますか？',
    C031: '{1}を削除しますか？',
    C032: '選択している作業員にメールを送信しますか？',
    C033: 'パスワードを再発行しますか？',
    C034: '<p style="color:red">{0}</p><p style="padding-left:10px">の 全作業の完了報告をしますか？</p>',
    C035: '下請会社名を削除しますか？',
    C036: '{1}を削除しますか？',
    C037: '<p>{0}さん</p><p>{1}</p><p>を 担当者から外してよろしいですか？</p>',
    C038: '通知を送信しますか？',
    C039: '<p class="mb-0">１次会社に下記帳票をメール送信しますか？</p><br><p class="mb-0">ＱＲコード</p>',
    C040: '<p class="mb-0">下記帳票を印刷しますか？</p><br><p class="mb-0">ＱＲコード</p>',
    C041: '変更内容が反映されませんが、よろしいですか？',
    C042: '部署マスタ・ユニットマスタ・チームマスタの一覧をＣＳＶでエクスポートしますか？',
    C043: '入力データを保存しますか？',
    C044: '<p class="mb-0">このサイトを離れますか？</p><br><p class="mb-0">行った変更が保存されない可能性があります。</p>',
    C045: '物件情報をＣＳＶでエクスポートしますか？',
    C046: '作業員の作業状況をCSVでエクスポートしますか？',
    // Success
    S001: 'データを保存しました',
    S002: '日締報告が解除されました',
    S003: '１次会社へメール通知しました。',
    S004: '担当者へメール通知しました。',
    // Error message
    E001: 'が入力されていません',
    E002: 'に日本語入力を不可にする',
    E003: '新しいパスワードと確認用のパスワードが不一致です',
    E004: '営業所を選択お願いします',
    E005: '更新する営業所が選択されていません',
    E006: '削除する営業所が選択されていません',
    E007: '更新する従業員が選択されていません',
    E008: '更新する職務権限が選択されていません',
    E009: '更新する取引先が選択されていません',
    E010: '削除する取引先が選択されていません',
    E011: '通知する取引先が選択されていません',
    E012: '削除する従業員が選択されていません',
    E013: '更新する作業依頼内容が選択されていません',
    E014: '削除する作業依頼内容名が選択されていません',
    E015: '更新する資格が選択されていません',
    E016: '削除する資格が選択されていません',
    E017: '削除する職務権限が選択されていません',
    E018: '新規入場遵守事項内容を入力してください',
    E019: '１次会社作業完了承認の解除をしてから実施してください',
    E020: '新規入場遵守事項内容を入力してください',
    E021: '削除する物件が選択されていません',
    E022: '印刷したい作業日を選択してください。',
    E023: '新規入場の事前依頼を行っているため、削除できません',
    E024: '作業員名を入力してください',
    E025: 'メールドレスを入力してください',
    E026: 'メール送信対象者が選択されていません。',
    E027: '作業日の範囲を確認してください。',
    E028: '全体工期の範囲を確認してください。',
    E029: 'の形式が間違っています',
    E030: 'メールアドレスを入力してください',
    E031: '作業員が選択されていません',
    E032: '作業員名を入力してください',
    E033: '担当者がいなくなってしまうため、解除できません。',
    E034: '氏名/メールアドレスが入力されていません',
    E035: 'メールアドレスの形式が正しくありません',
    E036: '操作日の範囲を確認してください。',
    E037: '印刷したい作業員を選択してください。',
    E038: '１次会社、または送付先が選択されていません。',
    E039: '認証コードを入力してください',
    E040: '遵守事項を確認し全てチェックしてください',
    E041: '出力処理がタイムアウトになりました',
    E042: '基本遵守項目を全て確認し、チェックしてください',
    E043: 'KY項目を全て確認し、チェックしてください',
    E044: 'サインしてください',
    E045: '質問内容が入力されていません',
    E046: '質問内容が変更されていないか、入力されていません',
    E047: '削除する質問内容が選択されていません',
    E048: '1つ以上選択してください。',
    E049: '１次会社を指定してください。',
    E050: '印刷する書類を選択してください',
    E051: '質問の分類を選択してください',
    E052: '健康状態で「いいえ」が選択されています',
    E053: '更新する組織名が選択されていません',
    E054: '削除する組織名が選択されていません',
    E055: '組織名が入力されていません',
    E056: '並び順が入力されていません',
    E057: '回答が入力されていません',
    E058: 'IDまたはパスワードが有効ではありません',
    E059: '<p class="mb-0 text_left_ra200090i">作業日が設定されておらず、1日も作業完了報告が行われていないため、</p><p class="mb-0 text_left_ra200090i">全体完了報告ができません。</p><p class="mb-0 text_left_ra200090i">作業を行った日にチェックを入れ、作業完了報告を行ってください。</p>',
    // api error
    E400: 'エラーが発生しました。もう一度お願いします。',
    E401: 'ログインセッションの有効期限が切れています。もう一度ログインしてください。',
    E403: 'この機能にアクセスする権限がありません。',
    E404: 'エラーが発生しました。もう一度お願いします。',
    E422: 'エラーが発生しました。もう一度お願いします。',
    E423: 'エラーが発生しました。もう一度お願いします。',
    E500: 'エラーが発生しました。もう一度お願いします。',
    E999: 'エラーが発生しました。もう一度お願いします。'
};

export const MSG_TITLE = {
    C001: '新規登録確認',
    C002: '更新登録確認',
    C003: '削除確認',
    C004: '画面初期化確認',
    C005: 'データエクスポート確認',
    C006: 'データエクスポート確認',
    C007: 'パスワード通知確認',
    C008: 'データエクスポート確認',
    C009: 'データエクスポート確認',
    C010: '物件全体完了',
    C011: '物件全体完了の解除',
    C012: 'CSVを出力しますか？',
    C013: '完了報告の解除',
    C014: '１次会社作業完了の承認',
    C015: '１次会社作業完了承認の解除',
    C016: '一時保存確認',
    C017: '初期登録確認',
    C018: '更新登録確認',
    C019: '削除確認',
    C020: '担当者通知確認',
    C021: '１次会社への通知確認',
    C022: '送信確認',
    C028: '１次会社　日締報告の解除',
    C029: '安全書類印刷の確認',
    C030: '受領確認',
    C032: '送信確認',
    C033: 'パスワードの再発行',
    C034: '物件全体の作業完了報告',
    C035: 'クリックした行の氏名',
    C036: '１次会社へのメール送信',
    C037: '担当者の解除確認',
    C038: '安全書類の印刷',
    C039: 'キャンセル確認',
    C040: '全体完了エラー',
    S002: '解除しました',
    S003: '１次会社への通知',
    S004: '担当者への通知',
    E999: 'エラー',
    S999: '保存しました'
};

export const MSG_TYPE = {
    CONFIRM: 1,
    WARNING: 2,
    SUCCESS: 3,
    ERROR: 4
};
