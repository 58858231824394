export default {
    companyname: '会社名',
    companyabbreviation: '略称',
    representative: '代表者名',
    postalcode: '郵便番号',
    companyaddress1: '住所 ',
    companyaddress2: '',
    companytel: 'ＴＥＬ',
    companyfax: 'ＦＡＸ',
    insurancenumber: '労災保険番号',
    specialkyokacode: '建設業許可者',
    specialkyokano: '許可番号',
    specialkyokadate: '許可年月日',
    industries: '建設業種別',
    fcwarningsetting1: '初回',
    fcwarningsetting2: '2回目',
    fcwarningsetting3: '３回目以降',
    clientdaysetting1: '初回',
    clientdaysetting2: '2回目',
    clientdaysetting3: '３回目以降',
    clientallsetting1: '初回',
    clientallsetting2: '2回目',
    clientallsetting3: '３回目以降',
    changememo: '変更メモ'
};
