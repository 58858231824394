export default {
    bukken_nm: '物件名',
    customer_nm: '顧客名',
    jusho1: '住所1',
    jusho2: '住所2',
    kouki_F: '全体工期',
    kouki_T: '',
    kinkyudo: '緊急度',
    motouke_kb: '元請区分',
    kanri_kb: '管理区分',
    bukken_cd: '物件コード',
    tsuika_bukken: '追加物件コード',
    tantosha: '',
    gijutsusha: '技術者',

    tokutei_kb: '特定建設業',
    tokutei_gyoshuclass: '許可を受けた建設業',
    okutei_shubetsu: '技術者種別',
    tokutei_sennin: '専任の有無',
    tokutei_shikaku: '資格名',
    tokutei_kouhuno: '資格者証交付番号',
    ippan_kb: '一般建設業',
    koji_nm: '工事名',
    seshu: '施主',
    motouke_nm: '元請会社',
    motouke_eigyoshocode: '元請営業所',
    rosaihoken_no: '労災保険番号',
    eigyou_cd: '営業担当者',
    motokata_kaisha: '元方会社名',
    motokata_sekininsha: '元方工事責任者名',
    motokata_keitai: '元方工事責任者携帯'
};
