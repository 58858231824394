import messages from './messages';
import label from './label';
import store from '@/store';
export default {
    removeJapaneseCharacter: (str) => {
        if (str == undefined || str == null) {
            return '';
        }
        const regex =
            /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
        return str.replace(regex, '');
    },
    insertComma: (val) => {
        return (val + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    isValidData: (data, rules) => {
        try {
            const isEmail = (str) => {
                if (str == null || str == undefined || str == '') {
                    return true;
                }
                return String(str)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };
            let isValid = true;
            const screenId = store.state.app.screenId;
            for (const key in rules) {
                if (Object.prototype.hasOwnProperty.call(rules, key)) {
                    let tbl = label[screenId][key];
                    const value = rules[key];
                    const values = value.split('|');
                    const length = values.length;
                    if (tbl == undefined || tbl == null || tbl == '') {
                        tbl = '項目';
                    }
                    for (let i = 0; i < length; i++) {
                        const rule = values[i];
                        const checks = rule.split(':');
                        if (checks[0] == 'required') {
                            if (
                                !Object.prototype.hasOwnProperty.call(
                                    data,
                                    key
                                ) ||
                                data[key] == null ||
                                data[key] == ''
                            ) {
                                if (isValid) {
                                    store.commit('app/showHeaderError', [
                                        tbl + messages.E001
                                    ]);
                                }
                                isValid = false;
                            }
                        }
                        if (checks[0] == 'email') {
                            if (!isEmail(data[key])) {
                                if (isValid) {
                                    store.commit('app/showHeaderError', [
                                        messages.E035
                                    ]);
                                }
                                isValid = false;
                            }
                        }
                    }
                }
            }
            return isValid;
        } catch (e) {
            console.log('validData: ' + e.message);
            return false;
        }
    },

    /**
     *
     * @param {*} type type = 1 (type: 'characters')
     * @param {*} type type = 2 (type: 'string')
     * @param {*} type type = 3 (type: 'number')
     * @param {*} length
     * @returns
     */
    randomString: (type, length) => {
        try {
            let result = '';
            let lengthKey = 0;
            let Key = '';
            const characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const texts =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const numbers = '0123456789';
            if (type == 1) {
                lengthKey = characters.length;
                Key = characters;
            } else if (type == 2) {
                lengthKey = texts.length;
                Key = texts;
            } else {
                lengthKey = numbers.length;
                Key = numbers;
            }
            for (var i = 0; i < length; i++) {
                result += Key.charAt(Math.floor(Math.random() * lengthKey));
            }
            return result;
        } catch (e) {
            console.log('randomString: ' + e.message);
            return false;
        }
    },
    splitByType: (str) => {
        str = str + '';
        const length = str.length;
        let beforeChar = '';
        let childStr = '';
        const result = [];
        for (let i = 0; i < length; i++) {
            const currentChar = str[i];
            if (
                (isNaN(beforeChar) && !isNaN(currentChar)) ||
                (!isNaN(beforeChar) && isNaN(currentChar))
            ) {
                if (childStr != '') {
                    const child = '00000000000000000000' + childStr;
                    result.push(
                        !isNaN(childStr)
                            ? child.substring(child.length - 20, child.length)
                            : childStr
                    );
                    childStr = currentChar;
                } else {
                    childStr = currentChar;
                }
            } else {
                childStr += currentChar;
            }
            beforeChar = currentChar;
        }
        if (childStr != '') {
            const child = '00000000000000000000' + childStr;
            result.push(
                !isNaN(childStr)
                    ? child.substring(child.length - 20, child.length)
                    : childStr
            );
        }
        return result;
    },
    getNumber: (str, emptyIsMax) => {
        if (str == null || str == undefined || str == '') {
            if (emptyIsMax) {
                return Number.MAX_SAFE_INTEGER;
            }
            return 0;
        }
        str = (str + '').replace(/\D/g, '');
        if (str == '') {
            if (emptyIsMax) {
                return Number.MAX_SAFE_INTEGER;
            }
            return 0;
        }
        return parseInt(str);
    },
    isEmail: (str) => {
        if (str == null || str == undefined || str == '') {
            return true;
        }
        return String(str)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    },
    isNullOrEmpty: (str) => {
        if (str == undefined || str == null || str == '') {
            return true;
        }
        return false;
    },
    encodeParams: (params) => {
        try {
            if (params == undefined || params == null || params.length == 0) {
                return '';
            }
            let output = btoa(JSON.stringify(params));
            let endPos = 0;
            for (endPos = output.length; endPos > 0; endPos--) {
                if (output[endPos - 1] !== '=') {
                    break;
                }
            }
            const numberPaddingChars = output.length - endPos;
            output = output.replace(/\+/g, '-');
            output = output.replace(/\//g, '_');
            output = output.substring(0, endPos);
            output = output + numberPaddingChars;

            return output;
        } catch (e) {
            return '';
        }
    },
    decodeParams: (params) => {
        try {
            if (params == undefined || params == null || params.length == '') {
                return {};
            }
            var numberPading = parseInt(params.substring(params.length - 1));
            params = params.substring(0, params.length - 1);
            params = params.replace(/-/g, '+');
            params = params.replace(/_/g, '/');
            for (var j = 0; j < numberPading; j++) {
                params = params + '=';
            }
            params = params.replace(/[^A-Za-z0-9+/=]/g, '');
            params = atob(params);
            return JSON.parse(params);
        } catch (e) {
            return {};
        }
    }
};
