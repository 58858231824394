export default {
    workername: '氏名',
    workerkana: 'フリガナ',
    age: '年齢',
    bloodtype: '血液型',
    healthtype: '健康状態',
    shitaukename: '所属会社',
    departmentname: '所属部署',
    shitaukeaddress: '所属会社所在地',
    shitauketel: '所属会社連絡先',
    employmenttype: '雇用形態',
    industrialaccidentno: '特別労災番号',
    workdetail: '作業内容',
    titlecodelist: '遵守事項コード一覧',
    signfile: 'サインファイルの格納場所',
    checkRule: '個人情報の取り扱い'
};
