import repository from '@/utils/repository';
import store from '@/store';
import messages from '@/utils/messages';

const resource = '/common';

export default {
    getAppState: (data) => {
        return repository.get(`${resource}/app-state`, {
            params: data
        });
    },
    getLibraries: (data) => {
        return repository.get(`${resource}/libraries`, {
            params: {
                libraryno: data
            }
        });
    },
    getConstruction: () => {
        return repository.get(`${resource}/construction`);
    },
    getSalesOffice: () => {
        return repository.get(`${resource}/sales-office`);
    },
    getDepartments: () => {
        return repository.get(`${resource}/departments`);
    },
    getUnitGroups: () => {
        return repository.get(`${resource}/unit-groups`);
    },
    getTeam: () => {
        return repository.get(`${resource}/team`);
    },
    getDutyAuthority: () => {
        return repository.get(`${resource}/duty-authority`);
    },
    getFunction: () => {
        return repository.get(`${resource}/function`);
    },
    exportPDF: (body, callback) => {
        store.commit('app/showForceLoading');
        repository.post(`${resource}/export/pdf`, body).then((res) => {
            const { data } = res;
            const res1 = data;
            if (
                data.Code === 200 &&
                data.Data.returnno != undefined &&
                data.Data.returnno != null &&
                data.Data.returnno != ''
            ) {
                let count = 0;
                let requesting = false;
                const loop = setInterval(() => {
                    if (!requesting) {
                        requesting = true;
                        repository
                            .get(`${resource}/export/pdf/file`, {
                                params: {
                                    functionid: body.functionid,
                                    runno: data.Data.returnno
                                }
                            })
                            .then((res) => {
                                const { data } = res;
                                if (
                                    data.Code === 200 &&
                                    data.Data.outputstatus != undefined &&
                                    data.Data.outputstatus != null
                                ) {
                                    clearInterval(loop);
                                    store.commit('app/hideForceLoading');
                                    if (data.Data.outputstatus == 1) {
                                        if (!body.noPreview) {
                                            setTimeout(() => {
                                                const anchor =
                                                    document.createElement('a');
                                                anchor.href = `${data.Data.outputurl}`;
                                                anchor.target = '_blank';
                                                setTimeout(() => {
                                                    anchor.click();
                                                }, 100);
                                            }, 100);
                                        }
                                    } else {
                                        store.commit('app/showHeaderError', [
                                            data.Data.outputmessage
                                        ]);
                                    }
                                    if (typeof callback == 'function') {
                                        callback(res1, data);
                                    }
                                }
                                if (count >= 60 || data.Code !== 200) {
                                    clearInterval(loop);
                                    if (count >= 60) {
                                        store.commit('app/hideForceLoading');
                                        store.commit('app/showHeaderError', [
                                            messages.E041
                                        ]);
                                    }
                                    // if (typeof callback == 'function') {
                                    //     callback(res1, data);
                                    // }
                                }
                                count++;
                                requesting = false;
                            })
                            //.catch((e) => {
                            .catch(() => {
                                requesting = false;
                                clearInterval(loop);
                                // if (typeof callback == 'function') {
                                //     callback(res1, e);
                                // }
                            });
                    }
                }, 1000);
            }
        });
    }
};
