import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from 'dayjs';
import vClickOutside from 'click-outside-vue3';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueCookies from 'vue3-cookies';

import FcInput from '@/components/fcInput';
import FcDatePicker from '@/components/fcDatePicker';
import FcSelect from '@/components/fcSelect';
import FcCheckbox from '@/components/fcCheckbox';
import FcRadioButton from '@/components/fcRadioButton';
import FcFile from '@/components/fcFile';
import FcSwitch from '@/components/fcSwitch';
import FcButton from '@/components/fcButton';
import FcInputSearch from '@/components/fcInputSearch';
import FcList from '@/components/fcList';
import FcTable from '@/components/fcTable';
import FcPanelHeader from '@/components/fcPanelHeader';
import FcPanel from '@/components/fcPanel';
import VueSignaturePad from 'vue-signature-pad';

const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app.config.productionTip = false;
app.use(vClickOutside);
app.use(BootstrapVue3);
app.use(VueCookies, {
    expireTimes: '30d',
    path: '/'
});
app.use(store);
app.use(router);
app.use(VueSignaturePad);

app.component('FcInput', FcInput);
app.component('FcDatePicker', FcDatePicker);
app.component('FcSelect', FcSelect);
app.component('FcCheckbox', FcCheckbox);
app.component('FcRadioButton', FcRadioButton);
app.component('FcFile', FcFile);
app.component('FcSwitch', FcSwitch);
app.component('FcButton', FcButton);
app.component('FcInputSearch', FcInputSearch);
app.component('FcList', FcList);
app.component('FcTable', FcTable);
app.component('FcPanelHeader', FcPanelHeader);
app.component('FcPanel', FcPanel);

app.mount('#app');
